import React, { useEffect, useState } from 'react'
import Layout from "../components/layout"
import FrontPage from '../components/FrontPage';
import COLORS from '../resources/colors';
import { navigate } from "gatsby"
import { Location } from '@reach/router'
import queryString from 'query-string'
import Logo from "../images/logo-fun-cyan.svg"
import getPlayStoreUrl from '../getPlayStoreUrl';
import { trackCustomEvent } from 'gatsby-plugin-google-analytics'

const withLocation = ComponentToWrap => props => (
    <Location>
        {({ location, navigate }) => (
            <ComponentToWrap
                {...props}
                location={location}
                navigate={navigate}
                search={location.search ? queryString.parse(location.search) : {}}
            />
        )}
    </Location>
)

export default withLocation(Index)

function Index(props) {

    let [contentVisible, setContentVisible] = useState((props.search && props.search.payload) == undefined)

    useEffect(() => {
        if (contentVisible == false) {
            rerouteIfNeeded()
        }
    })

    let buttons = [
        {
            title: "GET THE APP",
            action: scrollToBottom
        },
        {
            title: "Login",
            action: onSecondaryButtonPressed,
            showOnMobile: true,
            isSecondary: true
        },
        {
            title: "Members",
            action: function () {
                navigate("/members")

                trackCustomEvent({
                    // string - required - The object that was interacted with (e.g.video)
                    category: "Button",
                    // string - required - Type of interaction (e.g. 'play')
                    action: "Click",
                    // string - optional - Useful for categorizing events (e.g. 'Spring Campaign')
                    label: "Members"
                })
            },
            selected: true
        },
        {
            title: "Companies",
            action: function () {
                navigate("/companies")

                trackCustomEvent({
                    // string - required - The object that was interacted with (e.g.video)
                    category: "Button",
                    // string - required - Type of interaction (e.g. 'play')
                    action: "Click",
                    // string - optional - Useful for categorizing events (e.g. 'Spring Campaign')
                    label: "Companies"
                })
            }
        },
        {
            title: "Lenders",
            action: function () {
                navigate("/")

                trackCustomEvent({
                    // string - required - The object that was interacted with (e.g.video)
                    category: "Button",
                    // string - required - Type of interaction (e.g. 'play')
                    action: "Click",
                    // string - optional - Useful for categorizing events (e.g. 'Spring Campaign')
                    label: "Lenders"
                })
            }
        },

    ]

    let menuButtons = [
        {
            title: "Lenders",
            type: "item",
            action: function () {
                navigate("/")

                trackCustomEvent({
                    // string - required - The object that was interacted with (e.g.video)
                    category: "Button",
                    // string - required - Type of interaction (e.g. 'play')
                    action: "Click",
                    // string - optional - Useful for categorizing events (e.g. 'Spring Campaign')
                    label: "Lenders"
                })
            }
        },
        {
            title: "Companies",
            type: "item",
            action: function () {
                navigate("/companies")

                trackCustomEvent({
                    // string - required - The object that was interacted with (e.g.video)
                    category: "Button",
                    // string - required - Type of interaction (e.g. 'play')
                    action: "Click",
                    // string - optional - Useful for categorizing events (e.g. 'Spring Campaign')
                    label: "Companies"
                })
            }
        },
        {
            title: "Members",
            type: "item",
            action: function () {
                navigate("/members")



                trackCustomEvent({
                    // string - required - The object that was interacted with (e.g.video)
                    category: "Button",
                    // string - required - Type of interaction (e.g. 'play')
                    action: "Click",
                    // string - optional - Useful for categorizing events (e.g. 'Spring Campaign')
                    label: "Members"
                })
            },
            selected: true
        },
    ]

    function rerouteIfNeeded() {

        try {
            let url = window.location.href
            var foundPayload = false

            let urlComponents = url.split("?")

            if (urlComponents.length == 2) {

                let query = urlComponents[1]

                let urlParameters = query.split("&")

                for (var param of urlParameters) {
                    if (param.includes("payload=")) {
                        foundPayload = true
                        window.location.href = `https://app.clerkie.io/?${urlComponents[1]}`
                    }
                }
            }
        } catch {

        }

    }

    function onSecondaryButtonPressed() {

        trackCustomEvent({
            // string - required - The object that was interacted with (e.g.video)
            category: "Button",
            // string - required - Type of interaction (e.g. 'play')
            action: "Click",
            // string - optional - Useful for categorizing events (e.g. 'Spring Campaign')
            label: "Login"
        })

        window.location.href = "https://app.clerkie.io"
    }

    function scrollToBottom() {

        var userAgent = navigator.userAgent || navigator.vendor || window.opera;
        var phoneType = ""

        if (/android/i.test(userAgent)) {
            phoneType = "Android";
        }

        if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
            phoneType = "iOS";
        }

        if (phoneType == "Android") {

            trackCustomEvent({
                // string - required - The object that was interacted with (e.g.video)
                category: "Button",
                // string - required - Type of interaction (e.g. 'play')
                action: "Click",
                // string - optional - Useful for categorizing events (e.g. 'Spring Campaign')
                label: "Android"
            })

            window.location.href = getPlayStoreUrl();
        } else if (phoneType == "iOS") {


            trackCustomEvent({
                // string - required - The object that was interacted with (e.g.video)
                category: "Button",
                // string - required - Type of interaction (e.g. 'play')
                action: "Click",
                // string - optional - Useful for categorizing events (e.g. 'Spring Campaign')
                label: "iOS"
            })

            window.location.href = "https://itunes.apple.com/us/app/clerkie-money-advisor-finance/id1132917036?mt=8";
        } else {
            window.scrollTo(0, document.body.scrollHeight);
        }
    }


    console.log("Content Visible", contentVisible)

    if (contentVisible == true) {
        return <Layout primaryLogo={Logo} alwaysPrimary={true} disableScrollAnimation={true} headerScrolledColor={"black"} secondaryButtonPrimaryBackground={"#333333"} secondaryButtonPrimaryColor={"white"} primaryColor={COLORS.funCyan} heroMobileMultiplier={0.54} heroDesktopMultiplier={2.0} buttons={buttons} menuButtons={menuButtons}>
            <FrontPage />
            <style global jsx="true">{`
      body { 
          background: black;
      }
      `}</style>
        </Layout>
    } else {
        return null
    }
}


